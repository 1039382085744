import { createFeatureFlag } from '../model';

const $$crowdinInContextFeatureFlag = createFeatureFlag(
  'FE_CHECKOUT_CROWDIN_IN_CONTEXT'
);

const $$isESLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_ES_LANGUAGE_ENABLED'
);

const $$recaptchaFeatureFlag = createFeatureFlag('RECAPTCHA_ENABLED');

const $$autoSelectIfOnlyOnePaymentMethodFeatureFlag = createFeatureFlag(
  'KUPAY_2703_FE_CHECKOUT_AUTO_SELECT_IF_ONLY_ONE_PAYMENT_METHOD'
);

export {
  $$autoSelectIfOnlyOnePaymentMethodFeatureFlag,
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$recaptchaFeatureFlag,
};
